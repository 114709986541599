<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :width="1100"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="入库"
      @ok="handleSubmit"
    >
      <a-row>
        <a-form
          class="simiot-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-col :span="11">
            <a-form-item :wrapper-col="{ span: 24 }">
              <common-card-input
                :rows="32"
                @change="handleIccidsChange"
                v-decorator="[
                  'iccids',
                  {
                    rules: [{ validator: checkIccids }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="13">
            <h4 class="title">物料属性</h4>

            <a-form-item label="卡片材质">
              <a-select
                v-decorator="[
                  'card_type_id',
                  {
                    rules: [{ required: true, message: '请选择卡片材质' }],
                  },
                ]"
                :loading="loadingCardTypeOptions"
                @focus="fetchCardTypeOptions"
                @change="handleCardTypeChange"
              >
                <a-select-option
                  v-for="option in cardTypeOptions"
                  :key="option.id"
                  :value="option.id"
                  :desp="option.name"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="卡片尺寸">
              <a-select
                v-decorator="[
                  'card_shape_name',
                  {
                    rules: [{ required: true, message: '请选择卡片尺寸' }],
                  },
                ]"
                :loading="loadingCardShapeOptions"
              >
                <a-select-option
                  v-for="option in cardShapeOptions"
                  :key="option.id"
                  :value="option.name"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="原生贴片" v-if="isShowNativeStm" required>
              <a-radio-group
                v-decorator="[
                  'is_native_smt',
                  {
                    rules: [{ required: true, message: '请确认选项' }],
                  },
                ]"
              >
                <a-radio :value="true"> 是 </a-radio>
                <a-radio :value="false"> 否 </a-radio>
              </a-radio-group>
            </a-form-item>

            <h4 class="title">运营商属性</h4>

            <a-form-item label="运营商种类">
              <a-select
                v-decorator="[
                  'carrier_type',
                  {
                    rules: [{ required: true, message: '请选择运营商种类' }],
                  },
                ]"
                @change="handleCarrierTypeChange"
              >
                <a-select-option
                  v-for="option in carrierTypeOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="运营商账户">
              <a-select
                v-decorator="[
                  'carrier_account_id',
                  {
                    rules: [{ required: true, message: '请选择运营商账户' }],
                  },
                ]"
                show-search
                :filter-option="filterOption"
                :loading="loadingCarrierAccountOptions"
                @change="handleCarrierAccountChange"
              >
                <a-select-option
                  v-for="option in carrierAccountOptions"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <h4 class="title">套餐属性</h4>

            <a-form-item label="运营商套餐">
              <a-select
                @change="handleCarrierProductChange"
                v-decorator="[
                  'carrier_product_id',
                  {
                    rules: [{ required: true, message: '请选择运营商套餐' }],
                  },
                ]"
                :loading="loadingCarrierAccountProductOptions"
              >
                <a-select-option
                  v-for="option in carrierAccountProductOptions"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="流量属性">
              <a-input disabled v-model="trafficProperty" />
            </a-form-item>

            <a-form-item v-if="directionalShow" label="VPDN分组ID">
              <a-input disabled v-decorator="['vpdn_id']" />
            </a-form-item>

            <a-form-item label="服务周期" required>
              <a-form-item
                :style="{ display: 'inline-block', width: '45%', marginBottom: 0 }"
              >
                <a-radio-group
                  v-decorator="[
                    'is_service_cycle_infinite',
                    {
                      rules: [{ required: true, message: '请选择服务周期' }],
                    },
                  ]"
                >
                  <a-radio :value="true"> 无限 </a-radio>
                  <a-radio :value="false"> 有限 </a-radio>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="isShowServiceCycleSetting"
                :style="{ display: 'inline-block', width: '55%', marginBottom: 0 }"
              >
                <a-space>
                  <a-input-number
                    :min="1"
                    :max="10000000"
                    :step="1"
                    :precision="0"
                    style="width: calc(100% - 10px)"
                    v-decorator="[
                      'service_cycle',
                      {
                        rules: [{ required: true, message: '请输入服务周期' }],
                      },
                    ]"
                  />
                  <span>个</span>
                </a-space>
              </a-form-item>
            </a-form-item>

            <a-form-item label="APN参数">
              <a-input
                v-decorator="[
                  'apn_parameters',
                  {
                    rules: [{ max: 100, message: '最多100个字符' }],
                  },
                ]"
              />
            </a-form-item>

            <a-form-item label="其他服务">
              <a-checkbox
                v-decorator="['is_exceed_package_stop', { valuePropName: 'checked' }]"
              >超套停</a-checkbox>
            </a-form-item>

            <h4 class="title">沉默期属性</h4>

            <a-form-item label="开卡日期">
              <a-date-picker
                style="width: 100%"
                v-decorator="[
                  'open_card_date',
                  {
                    rules: [{ required: true, message: '请选择开卡日期' }],
                  },
                ]"
              />
            </a-form-item>

            <a-form-item label="运营商沉默期">
              <a-date-picker
                style="width: 100%"
                v-decorator="[
                  'carrier_silent_period_end_date',
                  {
                    rules: [{ required: true, message: '请选择运营商沉默期' }],
                  },
                ]"
              />
            </a-form-item>

            <a-form-item label="测试功能">
              <a-checkbox
                v-decorator="['is_test_function', { valuePropName: 'checked' }]"
              />
            </a-form-item>

            <a-form-item label="运营商测试期" v-if="isShowTestFunctionSetting">
              <a-date-picker
                style="width: 100%"
                v-decorator="[
                  'carrier_test_period_end_date',
                  {
                    rules: [{ required: true, message: '请选择运营商测试期' }],
                  },
                ]"
              />
            </a-form-item>

            <a-form-item required label="测试流量" v-if="isShowTestFunctionSetting">
              <a-form-item
                :style="{ display: 'inline-block', width: '70%', marginBottom: 0 }"
              >
                <a-input-number
                  :min="1"
                  :max="10000000"
                  :step="1"
                  :precision="0"
                  style="width: calc(100% - 10px)"
                  v-decorator="[
                    'carrier_test_traffic',
                    {
                      rules: [{ required: true, message: '请输入测试流量' }],
                    },
                  ]"
                />
              </a-form-item>

              <a-form-item
                :style="{ display: 'inline-block', width: '30%', marginBottom: 0 }"
              >
                <a-select
                  v-decorator="[
                    'carrier_test_traffic_unit',
                    {
                      initialValue: 'kb',
                      rules: [{ required: true, message: '请选择流量单位' }],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="option in trafficUnitOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-form-item>
            <h4 class="title">卡板费用</h4>

            <a-form-item label="卡板价格(元/张)">
              <a-input-number
                :min="0"
                :max="10000000"
                :step="0.01"
                :precision="2"
                style="width: 356.14px"
                @change="handleCarrierCardPriceChange"
                v-decorator="[
                  'carrier_card_price',
                  {
                    rules: [
                      { required: true, message: '请填写卡板价格' },
                      { type: 'number', message: '请输入 0 - 10000000 之间的数字' },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item label="卡板总金额(元)">
              <a-input-number
                :disabled="true"
                :step="0.01"
                :precision="2"
                style="width: 356.14px"
                v-decorator="['carrier_total_card_fee']"
              />
            </a-form-item>

            <h4 class="title">其他属性</h4>

            <a-form-item label="备注">
              <a-textarea
                :rows="3"
                v-decorator="[
                  'remark',
                  {
                    rules: [{ max: 200, message: '最多200个字符' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { importInventoryCards } from '@/api/sim_card_inventory'
import { numberRoundTwo } from '@/utils/math'
import {
  findCardShapeOptions,
  findCardTypeOptions,
  findPurchasableCarrierAccountProductOptions,
  findCarrierAccountOptions,
  findCarrierTypeOptions,
  findTrafficUintOptions
} from '@/api/common_options'
import { formatDate } from '@/utils/time'
import CommonCardInput from '@/components/CardInput/Common'

export default {
  name: 'ImportSimCardInventory',
  components: { CommonCardInput },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    purchaseOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      carrierTypeOptions: findCarrierTypeOptions(),
      carrierAccountOptions: [],
      carrierAccountProductOptions: [],
      cardTypeOptions: [],
      cardShapeOptions: [],
      trafficPropertyName: {
        general: '通用',
        directional: '定向'
      },
      trafficProperty: '',
      directionalShow: false,
      vpdn_id: '99999_E',
      carrierAccountProductOptionsMap: {},
      trafficUnitOptions: findTrafficUintOptions(),
      loadingCarrierAccountOptions: false,
      loadingCarrierAccountProductOptions: false,
      loadingCardTypeOptions: false,
      loadingCardShapeOptions: false,
      isShowTestFunctionSetting: false,
      isShowServiceCycleSetting: false,
      isShowNativeStm: false,
      submitting: false,
      form: this.$form.createForm(this, {
        name: 'import',
        onValuesChange: this.onValuesChange
      }),
      cardCount: 0,
      cardFee: 0
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    totalFee() {
      return numberRoundTwo(this.cardFee * this.cardCount)
    }
  },
  methods: {
    handleCarrierCardPriceChange(value) {
      this.cardFee = value
      this.form.setFieldsValue({
        carrier_total_card_fee: this.totalFee
      })
    },

    handleIccidsChange(value) {
      this.cardCount = value.cardsCount - value.duplicateCardsCount
      this.form.setFieldsValue({
        carrier_total_card_fee: this.totalFee
      })
    },

    checkIccids(rule, value, callback) {
      if (!value || !value.inputValue) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入卡号')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    onValuesChange(props, values) {
      if (values.is_test_function !== undefined) {
        this.isShowTestFunctionSetting = values.is_test_function
      }

      if (values.is_service_cycle_infinite !== undefined) {
        this.isShowServiceCycleSetting = !values.is_service_cycle_infinite
      }
    },

    // 加载卡片类型选项
    fetchCardTypeOptions() {
      if (this.cardTypeOptions.length === 0) {
        this.loadingCardTypeOptions = true
        findCardTypeOptions().then((res) => {
          if (res.code === 0) {
            this.cardTypeOptions = res.data
            this.loadingCardTypeOptions = false
          }
        })
      }
    },

    // 卡片材质变更，加载卡片尺寸
    handleCardTypeChange(cardTypeId, e) {
      console.log(e)
      this.loadingCardShapeOptions = true
      // 清空选项
      this.cardShapeOptions = []
      // 清空选项选中的值
      this.form.setFieldsValue({
        card_shape_name: undefined
      })

      if (cardTypeId) {
        findCardShapeOptions(cardTypeId).then((res) => {
          if (res.code === 0) {
            this.loadingCardShapeOptions = false
            this.cardShapeOptions = res.data
          }
        })
      }

      if (e.data.attrs.desp.indexOf('插拔') >= 0) {
        this.isShowNativeStm = true
      } else {
        this.isShowNativeStm = false
      }
    },

    // 运营商种类变更，加载运营商账户和运营商状态
    handleCarrierTypeChange(carrierType) {
      this.loadingCarrierAccountOptions = true
      // 清空选项
      this.carrierAccountOptions = []
      this.carrierAccountProductOptions = []
      this.carrierAccountProductOptionsMap = []
      this.trafficProperty = ''
      // 清空选项选中的值
      this.form.setFieldsValue({
        carrier_account_id: undefined,
        carrier_product_id: undefined,
        vpdn_id: undefined
      })
      this.directionalShow = false

      if (carrierType) {
        findCarrierAccountOptions(carrierType).then((res) => {
          if (res.code === 0) {
            this.loadingCarrierAccountOptions = false
            this.carrierAccountOptions = res.data
          }
        })
      }
    },

    handleCarrierProductChange(carrierProductId) {
      this.trafficProperty = this.trafficPropertyName[
        this.carrierAccountProductOptionsMap[carrierProductId]
          ? this.carrierAccountProductOptionsMap[carrierProductId].traffic_property
          : ''
      ]
      this.directionalShow =
        this.carrierAccountProductOptionsMap[carrierProductId] &&
        this.carrierAccountProductOptionsMap[carrierProductId].traffic_property ===
          'directional'

      // 定向流量
      if (this.directionalShow) {
        this.$nextTick(() => {
          this.form.setFieldsValue({
            vpdn_id: this.vpdn_id
          })
        })
      }
    },

    // 运营商账户变更，加载运营商账户
    handleCarrierAccountChange(carrierAccountId) {
      this.loadingCarrierAccountProductOptions = true
      // 清空选项
      this.carrierAccountProductOptions = []
      this.carrierAccountProductOptionsMap = []
      this.trafficProperty = ''
      // 清空选项选中的值
      this.form.setFieldsValue({
        carrier_product_id: undefined,
        vpdn_id: undefined
      })
      this.directionalShow = false

      if (carrierAccountId) {
        findPurchasableCarrierAccountProductOptions(carrierAccountId).then((res) => {
          if (res.code === 0) {
            this.loadingCarrierAccountProductOptions = false
            this.carrierAccountProductOptions = res.data
            res.data.forEach((element) => {
              this.carrierAccountProductOptionsMap[element.id] = element
            })
          }
        })
      }
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            iccids: values.iccids.data,
            purchase_order_id: this.purchaseOrderId,
            open_card_date: formatDate(values.open_card_date),
            carrier_silent_period_end_date: formatDate(
              values.carrier_silent_period_end_date
            ),
            carrier_test_period_end_date: formatDate(values.carrier_test_period_end_date)
          }
          importInventoryCards(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
              this.$success({
                title: '提交成功',
                content: (
                  <div>
                    <p>操作成功，具体操作结果请至 【系统查询】-【批量操作查询】 查看</p>
                  </div>
                )
              })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  color: @primary-color;
  padding-left: 50px;
}
</style>
