var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","width":1100,"mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"入库"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-row',[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-col',{attrs:{"span":11}},[_c('a-form-item',{attrs:{"wrapper-col":{ span: 24 }}},[_c('common-card-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'iccids',
                {
                  rules: [{ validator: _vm.checkIccids }],
                } ]),expression:"[\n                'iccids',\n                {\n                  rules: [{ validator: checkIccids }],\n                },\n              ]"}],attrs:{"rows":32},on:{"change":_vm.handleIccidsChange}})],1)],1),_c('a-col',{attrs:{"span":13}},[_c('h4',{staticClass:"title"},[_vm._v("物料属性")]),_c('a-form-item',{attrs:{"label":"卡片材质"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'card_type_id',
                {
                  rules: [{ required: true, message: '请选择卡片材质' }],
                } ]),expression:"[\n                'card_type_id',\n                {\n                  rules: [{ required: true, message: '请选择卡片材质' }],\n                },\n              ]"}],attrs:{"loading":_vm.loadingCardTypeOptions},on:{"focus":_vm.fetchCardTypeOptions,"change":_vm.handleCardTypeChange}},_vm._l((_vm.cardTypeOptions),function(option){return _c('a-select-option',{key:option.id,attrs:{"value":option.id,"desp":option.name}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"卡片尺寸"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'card_shape_name',
                {
                  rules: [{ required: true, message: '请选择卡片尺寸' }],
                } ]),expression:"[\n                'card_shape_name',\n                {\n                  rules: [{ required: true, message: '请选择卡片尺寸' }],\n                },\n              ]"}],attrs:{"loading":_vm.loadingCardShapeOptions}},_vm._l((_vm.cardShapeOptions),function(option){return _c('a-select-option',{key:option.id,attrs:{"value":option.name}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1),(_vm.isShowNativeStm)?_c('a-form-item',{attrs:{"label":"原生贴片","required":""}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'is_native_smt',
                {
                  rules: [{ required: true, message: '请确认选项' }],
                } ]),expression:"[\n                'is_native_smt',\n                {\n                  rules: [{ required: true, message: '请确认选项' }],\n                },\n              ]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" 否 ")])],1)],1):_vm._e(),_c('h4',{staticClass:"title"},[_vm._v("运营商属性")]),_c('a-form-item',{attrs:{"label":"运营商种类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'carrier_type',
                {
                  rules: [{ required: true, message: '请选择运营商种类' }],
                } ]),expression:"[\n                'carrier_type',\n                {\n                  rules: [{ required: true, message: '请选择运营商种类' }],\n                },\n              ]"}],on:{"change":_vm.handleCarrierTypeChange}},_vm._l((_vm.carrierTypeOptions),function(option){return _c('a-select-option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"运营商账户"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'carrier_account_id',
                {
                  rules: [{ required: true, message: '请选择运营商账户' }],
                } ]),expression:"[\n                'carrier_account_id',\n                {\n                  rules: [{ required: true, message: '请选择运营商账户' }],\n                },\n              ]"}],attrs:{"show-search":"","filter-option":_vm.filterOption,"loading":_vm.loadingCarrierAccountOptions},on:{"change":_vm.handleCarrierAccountChange}},_vm._l((_vm.carrierAccountOptions),function(option){return _c('a-select-option',{key:option.id,attrs:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1),_c('h4',{staticClass:"title"},[_vm._v("套餐属性")]),_c('a-form-item',{attrs:{"label":"运营商套餐"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'carrier_product_id',
                {
                  rules: [{ required: true, message: '请选择运营商套餐' }],
                } ]),expression:"[\n                'carrier_product_id',\n                {\n                  rules: [{ required: true, message: '请选择运营商套餐' }],\n                },\n              ]"}],attrs:{"loading":_vm.loadingCarrierAccountProductOptions},on:{"change":_vm.handleCarrierProductChange}},_vm._l((_vm.carrierAccountProductOptions),function(option){return _c('a-select-option',{key:option.id,attrs:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"流量属性"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(_vm.trafficProperty),callback:function ($$v) {_vm.trafficProperty=$$v},expression:"trafficProperty"}})],1),(_vm.directionalShow)?_c('a-form-item',{attrs:{"label":"VPDN分组ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['vpdn_id']),expression:"['vpdn_id']"}],attrs:{"disabled":""}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"服务周期","required":""}},[_c('a-form-item',{style:({ display: 'inline-block', width: '45%', marginBottom: 0 })},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'is_service_cycle_infinite',
                  {
                    rules: [{ required: true, message: '请选择服务周期' }],
                  } ]),expression:"[\n                  'is_service_cycle_infinite',\n                  {\n                    rules: [{ required: true, message: '请选择服务周期' }],\n                  },\n                ]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" 无限 ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" 有限 ")])],1)],1),(_vm.isShowServiceCycleSetting)?_c('a-form-item',{style:({ display: 'inline-block', width: '55%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'service_cycle',
                    {
                      rules: [{ required: true, message: '请输入服务周期' }],
                    } ]),expression:"[\n                    'service_cycle',\n                    {\n                      rules: [{ required: true, message: '请输入服务周期' }],\n                    },\n                  ]"}],staticStyle:{"width":"calc(100% - 10px)"},attrs:{"min":1,"max":10000000,"step":1,"precision":0}}),_c('span',[_vm._v("个")])],1)],1):_vm._e()],1),_c('a-form-item',{attrs:{"label":"APN参数"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'apn_parameters',
                {
                  rules: [{ max: 100, message: '最多100个字符' }],
                } ]),expression:"[\n                'apn_parameters',\n                {\n                  rules: [{ max: 100, message: '最多100个字符' }],\n                },\n              ]"}]})],1),_c('a-form-item',{attrs:{"label":"其他服务"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_exceed_package_stop', { valuePropName: 'checked' }]),expression:"['is_exceed_package_stop', { valuePropName: 'checked' }]"}]},[_vm._v("超套停")])],1),_c('h4',{staticClass:"title"},[_vm._v("沉默期属性")]),_c('a-form-item',{attrs:{"label":"开卡日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'open_card_date',
                {
                  rules: [{ required: true, message: '请选择开卡日期' }],
                } ]),expression:"[\n                'open_card_date',\n                {\n                  rules: [{ required: true, message: '请选择开卡日期' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"运营商沉默期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'carrier_silent_period_end_date',
                {
                  rules: [{ required: true, message: '请选择运营商沉默期' }],
                } ]),expression:"[\n                'carrier_silent_period_end_date',\n                {\n                  rules: [{ required: true, message: '请选择运营商沉默期' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"测试功能"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_test_function', { valuePropName: 'checked' }]),expression:"['is_test_function', { valuePropName: 'checked' }]"}]})],1),(_vm.isShowTestFunctionSetting)?_c('a-form-item',{attrs:{"label":"运营商测试期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'carrier_test_period_end_date',
                {
                  rules: [{ required: true, message: '请选择运营商测试期' }],
                } ]),expression:"[\n                'carrier_test_period_end_date',\n                {\n                  rules: [{ required: true, message: '请选择运营商测试期' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1):_vm._e(),(_vm.isShowTestFunctionSetting)?_c('a-form-item',{attrs:{"required":"","label":"测试流量"}},[_c('a-form-item',{style:({ display: 'inline-block', width: '70%', marginBottom: 0 })},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'carrier_test_traffic',
                  {
                    rules: [{ required: true, message: '请输入测试流量' }],
                  } ]),expression:"[\n                  'carrier_test_traffic',\n                  {\n                    rules: [{ required: true, message: '请输入测试流量' }],\n                  },\n                ]"}],staticStyle:{"width":"calc(100% - 10px)"},attrs:{"min":1,"max":10000000,"step":1,"precision":0}})],1),_c('a-form-item',{style:({ display: 'inline-block', width: '30%', marginBottom: 0 })},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'carrier_test_traffic_unit',
                  {
                    initialValue: 'kb',
                    rules: [{ required: true, message: '请选择流量单位' }],
                  } ]),expression:"[\n                  'carrier_test_traffic_unit',\n                  {\n                    initialValue: 'kb',\n                    rules: [{ required: true, message: '请选择流量单位' }],\n                  },\n                ]"}]},_vm._l((_vm.trafficUnitOptions),function(option){return _c('a-select-option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1)],1):_vm._e(),_c('h4',{staticClass:"title"},[_vm._v("卡板费用")]),_c('a-form-item',{attrs:{"label":"卡板价格(元/张)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'carrier_card_price',
                {
                  rules: [
                    { required: true, message: '请填写卡板价格' },
                    { type: 'number', message: '请输入 0 - 10000000 之间的数字' } ],
                } ]),expression:"[\n                'carrier_card_price',\n                {\n                  rules: [\n                    { required: true, message: '请填写卡板价格' },\n                    { type: 'number', message: '请输入 0 - 10000000 之间的数字' },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"356.14px"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2},on:{"change":_vm.handleCarrierCardPriceChange}})],1),_c('a-form-item',{attrs:{"label":"卡板总金额(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['carrier_total_card_fee']),expression:"['carrier_total_card_fee']"}],staticStyle:{"width":"356.14px"},attrs:{"disabled":true,"step":0.01,"precision":2}})],1),_c('h4',{staticClass:"title"},[_vm._v("其他属性")]),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remark',
                {
                  rules: [{ max: 200, message: '最多200个字符' }],
                } ]),expression:"[\n                'remark',\n                {\n                  rules: [{ max: 200, message: '最多200个字符' }],\n                },\n              ]"}],attrs:{"rows":3}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }